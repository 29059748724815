import axios from "../../../services/Api";

export default {
    methods: {
        async handleRegister() {
            this.saveOwQueryParams();
            
            if (this.acceptAgreement == true) {
                this.loading = true;
                await axios.get("sanctum/csrf-cookie")
                    .then(() => {
                        axios.post("front-catalog/register", this.userForm)
                            .then(() => {
                                this.loading = false;
                                this.successCreated = true;
                            })
                            .catch((error) => {
                                this.failErrorData = error.response.data;
                                this.failCreated = true;
                                this.loading = false;
                            });
                    });
            } else {
                this.acceptPolicy = true;
            }
        },
        saveOwQueryParams() {
            this.userForm.ow_params = {};
            for (let key in this.$route.query) {
                if (key.startsWith('ow_')) {
                    this.userForm.ow_params[key] = this.$route.query[key];
                }
            }
        }
    },
};
